import React from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import WYRKRRHOMEV1 from "./components/WYRKRRHOMEV1";
import Contact from "./components/Contact";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/:path(|wyrkrr-home-v1)">
          <WYRKRRHOMEV1 {...wYRKRRHOMEV1Data} />
        </Route>
        <Route path="/business">
          <WYRKRRHOMEV1 {...wYRKRRBUSINESSV1Data} />
        </Route>
        <Route path="/wyrkrr">
          <WYRKRRHOMEV1 {...wYRKRRWYRKRRV1Data} />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
const wYRKRRHOMEV1Data = {
  getStarted1: "Get Started",
  contactUs: "Contact Us",
  watchVideo: "watch video",
  spanText1: "Home",
  spanText2: "         About         How it works         Features",
  spanText3: "Maximize your efficiency and profit with ",
  spanText4: "Wyrkrr!",
  wyrkrrLogo21: "/img/wyrkrr-logo-2@2x.png",
  howItWorks: "How it works",
  number1: "01",
  number2: "02",
  number3: "03",
  number4: "04",
  // createAJob: "Create a job",
  createAJob: "Rent a device",
  x29428421: "/img/2942842-1@2x.png",
  // remotelyControlDevice: "Remotely control device",
  remotelyControlDevice: "Install device and connect to the internet",
  x10866861: "/img/1086686-1@2x.png",
  // theGigWorker: "The gig worker",
  theGigWorker: "Create Task to be completed",
  x32348001: "/img/3234800-1@2x.png",
  // taskAndGetTheTime: "Task and get the time",
  taskAndGetTheTime: "Worker remotely controls device",
  x12483241: "/img/1248324-1@2x.png",
  spanText5: "So, what are you waiting for? ",
  spanText6: "Get started with Wyrkrr today!",
  spanText7: "",
  spanText8: "Endless advantages with ",
  spanText9: "Wyrkrr.",
  spanText10: "",
  thisSystemHasANu:
    "This system has a number of advantages for businesses. First, it decreases the startup cost of using robots, since you only need to rent them instead of buying them outright. Second, it reduces the amount of technical knowledge needed to use them, since you can operate them remotely. Finally, it provides a flexible workforce that can be scaled up or down as needed.",
  soIfYouReLookin:
    "So, if you're looking for a way to automate your business and stay ahead of the competition, Wyrkrr is the perfect solution.",
  getStarted2: "Get Started",
  ellipse44: "/img/ellipse-44@1x.png",
  ellipse48: "/img/ellipse-48@1x.png",
  ellipse49: "/img/ellipse-49@1x.png",
  uiBlurTriangle11: "/img/ui-blur-triangle1-1@2x.png",
  uiBlurTriangle61: "/img/ui-blur-triangle6-1@2x.png",
  uiBlurTriangle21: "/img/ui-blur-triangle2-1@2x.png",
  uiBlurTriangle22: "/img/ui-blur-triangle2-2@2x.png",
  rectangle83: "/img/rectangle-83@1x.png",
  spanText11: "The key benefits of using ",
  spanText12: "Wyrkrr",
  forYourSmallOrMe: (
    <React.Fragment>
      For your small or <br />
      medium-sized business:
    </React.Fragment>
  ),
  onDemandRobotsMay:
    "On-demand robots may be utilized to supplement the workforce in the event that workers are absent or insufficiently staffed.",
  x27790451: "/img/2779045-1@2x.png",
  increaseUptimeThro:
    "Increase uptime/throughput to be open any hours of the day you want.",
  x47120231: "/img/4712023-1@2x.png",
  reduceLaborExpense: "Reduce labor expenses by paying per task.",
  x29426641: "/img/2942664-1@2x.png",
  spanText13: (
    <React.Fragment>
      For gig workers
      <br />
      with{" "}
    </React.Fragment>
  ),
  spanText14: "Wyrkrr",
  youSetYourHours: "You set your hours - Work whenever you want.",
  workFromAnywhereYouWant: "Work from anywhere you want!",
  setYourOwnPayO: "Set your own pay - Only accept jobs that pay what you want",
  noNeedToCommute: "No need to commute - Save time and money",
  rectangle87: "/img/rectangle-87@1x.png",
  fromGreatOurClients: "From great our clients",
  name1: "Adam Store",
  name2: "Adam Store",
  ceoAtGoogleInc1: " / CEO at google INC",
  ceoAtGoogleInc2: " / CEO at google INC",
  ellipse55: "/img/ellipse-55@2x.png",
  ellipse56: "/img/ellipse-56@2x.png",
  replacingAMaintain1:
    "Replacing a maintains the amount of lines. When replacing a selection. help agencies to define their new business objectives and then create.",
  replacingAMaintain2:
    "Replacing a maintains the amount of lines. When replacing a selection. help agencies to define their new business objectives and then create.",
  x11: "/img/1-1@2x.png",
  x2942665: "/img/2-942665@2x.png",
  x31: "/img/3-1@2x.png",
  x4712024: "/img/4-712024@2x.png",
  x51: "/img/5-1@2x.png",
  spanText15: (
    <React.Fragment>
      About us
      <br />
    </React.Fragment>
  ),
  spanText16: (
    <React.Fragment>
      About Us
      <br />
      ayAngel Rewards
      <br />
      PayAngel RemitCare
    </React.Fragment>
  ),
  spanText17: (
    <React.Fragment>
      Help Centre
      <br />
    </React.Fragment>
  ),
  spanText18: (
    <React.Fragment>
      How It Works
      <br />
      FAQ
      <br />
      Security
      <br />
      Customer Complaints
    </React.Fragment>
  ),
  spanText19: (
    <React.Fragment>
      Legal Stuff
      <br />
    </React.Fragment>
  ),
  spanText20: (
    <React.Fragment>
      Terms And Conditions
      <br />
      Privacy Policy
      <br />
      Acceptable Use Policy
      <br />
      Cookies Policy
    </React.Fragment>
  ),
  shd1Wd91: "/img/shd1wd9-1@2x.png",
  wyrkrrLogo22: "/img/wyrkrr-logo-2@2x.png",
  subscribeToOurNewsletter: (
    <React.Fragment>
      Subscribe
      <br />
      to our newsletter
    </React.Fragment>
  ),
  enterYourEMailAddress: "Enter your e-mail address",
  spanText21: "Legal",
  spanText22: "        Privacy Policy        Impressum",
  x2_Png2Copy: "/img/2-png--2----copy@2x.png",
  text1: "",
  text2: "",
  text4: "",
  text3: "",
  rectangle88: "/img/rectangle-88@1x.png",
  rectangle97: "/img/rectangle-97@1x.png",
};
const wYRKRRBUSINESSV1Data = {
  getStarted1: "Get Started",
  contactUs: "Contact Us",
  watchVideo: "watch video",
  spanText1: "Home",
  spanText2: "         About         How it works         Features",
  spanText3: "See the difference in your business with ",
  spanText4: "Wyrkrr!",
  wyrkrrLogo21: "/img/wyrkrr-logo-2@2x.png",
  howItWorks: "Devices",
  number1: "01",
  number2: "02",
  number3: "03",
  number4: "04",
  // createAJob: "Create a job",
  createAJob: "Mechanical Arms",
  x29428421: "/img/2942842-1@2x.png",
  // remotelyControlDevice: "Remotely control device",
  remotelyControlDevice: "3D Printers",
  x10866861: "/img/1086686-1@2x.png",
  // theGigWorker: "The gig worker",
  theGigWorker: "Robotic Vehicles",
  x32348001: "/img/3234800-1@2x.png",
  // taskAndGetTheTime: "Task and get the time",
  taskAndGetTheTime: "Delivery Drones",
  x12483241: "/img/1248324-1@2x.png",
  spanText5: "So, what are you waiting for? ",
  spanText6: "Get started with Wyrkrr today!",
  spanText7: "",
  spanText8: "Endless advantages with ",
  spanText9: "Wyrkrr.",
  spanText10: "",
  thisSystemHasANu:
    "We provide comprehensive robotic services for small and medium-sized businesses. Our goal is to help businesses automate their operations, increase efficiency, reduce cost, and stay competitive in today's market. We offer a variety of robotic equipment for rent so that you can get started right away!",
  soIfYouReLookin:
    "To get started, simply contact us, and choose the equipment you want to rent for your business. We'll deliver it to your doorstep so you can get started right away!",
  getStarted2: "Get Started",
  ellipse44: "/img/ellipse-44@1x.png",
  ellipse48: "/img/ellipse-48@1x.png",
  ellipse49: "/img/ellipse-49@1x.png",
  uiBlurTriangle11: "/img/ui-blur-triangle1-1@2x.png",
  uiBlurTriangle61: "/img/ui-blur-triangle6-1@2x.png",
  uiBlurTriangle21: "/img/ui-blur-triangle2-1@2x.png",
  uiBlurTriangle22: "/img/ui-blur-triangle2-2@2x.png",
  rectangle83: "/img/rectangle-83@1x.png",
  spanText11: "The key benefits of using ",
  spanText12: "Wyrkrr",
  forYourSmallOrMe: (
    <React.Fragment>
      For your small or <br />
      medium-sized business:
    </React.Fragment>
  ),
  onDemandRobotsMay:
    "On-demand robots may be utilized to supplement the workforce in the event that workers are absent or insufficiently staffed.",
  x27790451: "/img/2779045-1@2x.png",
  increaseUptimeThro:
    "Increase uptime/throughput to be open any hours of the day you want.",
  x47120231: "/img/4712023-1@2x.png",
  reduceLaborExpense: "Reduce labor expenses by paying per task.",
  x29426641: "/img/2942664-1@2x.png",
  spanText13: (
    <React.Fragment>
      For gig workers
      <br />
      with{" "}
    </React.Fragment>
  ),
  spanText14: "Wyrkrr",
  youSetYourHours: "You set your hours - Work whenever you want.",
  workFromAnywhereYouWant: "Work from anywhere you want!",
  setYourOwnPayO: "Set your own pay - Only accept jobs that pay what you want",
  noNeedToCommute: "No need to commute - Save time and money",
  rectangle87: "/img/rectangle-87@1x.png",
  fromGreatOurClients: "From great our clients",
  name1: "Adam Store",
  name2: "Adam Store",
  ceoAtGoogleInc1: " / CEO at google INC",
  ceoAtGoogleInc2: " / CEO at google INC",
  ellipse55: "/img/ellipse-55@2x.png",
  ellipse56: "/img/ellipse-56@2x.png",
  replacingAMaintain1:
    "Replacing a maintains the amount of lines. When replacing a selection. help agencies to define their new business objectives and then create.",
  replacingAMaintain2:
    "Replacing a maintains the amount of lines. When replacing a selection. help agencies to define their new business objectives and then create.",
  x11: "/img/1-1@2x.png",
  x2942665: "/img/2-942665@2x.png",
  x31: "/img/3-1@2x.png",
  x4712024: "/img/4-712024@2x.png",
  x51: "/img/5-1@2x.png",
  spanText15: (
    <React.Fragment>
      About us
      <br />
    </React.Fragment>
  ),
  spanText16: (
    <React.Fragment>
      About Us
      <br />
      ayAngel Rewards
      <br />
      PayAngel RemitCare
    </React.Fragment>
  ),
  spanText17: (
    <React.Fragment>
      Help Centre
      <br />
    </React.Fragment>
  ),
  spanText18: (
    <React.Fragment>
      How It Works
      <br />
      FAQ
      <br />
      Security
      <br />
      Customer Complaints
    </React.Fragment>
  ),
  spanText19: (
    <React.Fragment>
      Legal Stuff
      <br />
    </React.Fragment>
  ),
  spanText20: (
    <React.Fragment>
      Terms And Conditions
      <br />
      Privacy Policy
      <br />
      Acceptable Use Policy
      <br />
      Cookies Policy
    </React.Fragment>
  ),
  shd1Wd91: "/img/shd1wd9-1@2x.png",
  wyrkrrLogo22: "/img/wyrkrr-logo-2@2x.png",
  subscribeToOurNewsletter: (
    <React.Fragment>
      Subscribe
      <br />
      to our newsletter
    </React.Fragment>
  ),
  enterYourEMailAddress: "Enter your e-mail address",
  spanText21: "Legal",
  spanText22: "        Privacy Policy        Impressum",
  x2_Png2Copy: "/img/2-png--2----copy@2x.png",
  text1: "",
  text2: "",
  text4: "",
  text3: "",
  rectangle88: "/img/rectangle-88@1x.png",
  rectangle97: "/img/rectangle-97@1x.png",
};
const wYRKRRWYRKRRV1Data = {
  getStarted1: "Get Started",
  contactUs: "Contact Us",
  watchVideo: "watch video",
  spanText1: "Home",
  spanText2: "         About         How it works         Features",
  spanText3: "See the difference in your Career with",
  spanText4: "Wyrkrr!",
  wyrkrrLogo21: "/img/wyrkrr-logo-2@2x.png",
  howItWorks: "Available roles",
  number1: "01",
  number2: "02",
  number3: "03",
  number4: "04",
  // createAJob: "Create a job",
  createAJob: "Mechanical Arm operators",
  x29428421: "/img/2942842-1@2x.png",
  // remotelyControlDevice: "Remotely control device",
  remotelyControlDevice: "3D Printer operators",
  x10866861: "/img/1086686-1@2x.png",
  // theGigWorker: "The gig worker",
  theGigWorker: "Robotic vehicle operators",
  x32348001: "/img/3234800-1@2x.png",
  // taskAndGetTheTime: "Task and get the time",
  taskAndGetTheTime: "Delivery drone operators",
  x12483241: "/img/1248324-1@2x.png",
  spanText5: "So, what are you waiting for? ",
  spanText6: "Get started with Wyrkrr today!",
  spanText7: "",
  spanText8: "Endless advantages with ",
  spanText9: "Wyrkrr.",
  spanText10: "",
  thisSystemHasANu:
    "If you're looking for the perfect job that has flexible hours and great pay, look no further! Wyrkrr is the perfect solution for you. We offer a variety of services that can be performed remotely, so you can work from anywhere in the world. And best of all, you set your own pay so you can only accept jobs that are worth your time.",
  soIfYouReLookin:
    "So, if you're looking for a way to automate your business and stay ahead of the competition, Wyrkrr is the perfect solution.",
  getStarted2: "Get Started",
  ellipse44: "/img/ellipse-44@1x.png",
  ellipse48: "/img/ellipse-48@1x.png",
  ellipse49: "/img/ellipse-49@1x.png",
  uiBlurTriangle11: "/img/ui-blur-triangle1-1@2x.png",
  uiBlurTriangle61: "/img/ui-blur-triangle6-1@2x.png",
  uiBlurTriangle21: "/img/ui-blur-triangle2-1@2x.png",
  uiBlurTriangle22: "/img/ui-blur-triangle2-2@2x.png",
  rectangle83: "/img/rectangle-83@1x.png",
  spanText11: "The key benefits of using ",
  spanText12: "Wyrkrr",
  forYourSmallOrMe: (
    <React.Fragment>
      For Gig Workers <br />
    </React.Fragment>
  ),
  onDemandRobotsMay:
    "Work from anywhere: as long as you have an internet connection, you can work from anywhere in the world.",
  x27790451: "/img/2779045-1@2x.png",
  increaseUptimeThro: "Flexible hours: You can work when you want!",
  x47120231: "/img/4712023-1@2x.png",
  reduceLaborExpense:
    "Great pay: You set your own pay, so you only accept jobs that you want",
  x29426641: "/img/2942664-1@2x.png",
  spanText13: (
    <React.Fragment>
      For gig workers
      <br />
      with{" "}
    </React.Fragment>
  ),
  spanText14: "Wyrkrr",
  youSetYourHours: "You set your hours - Work whenever you want.",
  workFromAnywhereYouWant: "Work from anywhere you want!",
  setYourOwnPayO: "Set your own pay - Only accept jobs that pay what you want",
  noNeedToCommute: "No need to commute - Save time and money",
  rectangle87: "/img/rectangle-87@1x.png",
  fromGreatOurClients: "From great our clients",
  name1: "Adam Store",
  name2: "Adam Store",
  ceoAtGoogleInc1: " / CEO at google INC",
  ceoAtGoogleInc2: " / CEO at google INC",
  ellipse55: "/img/ellipse-55@2x.png",
  ellipse56: "/img/ellipse-56@2x.png",
  replacingAMaintain1:
    "Replacing a maintains the amount of lines. When replacing a selection. help agencies to define their new business objectives and then create.",
  replacingAMaintain2:
    "Replacing a maintains the amount of lines. When replacing a selection. help agencies to define their new business objectives and then create.",
  x11: "/img/1-1@2x.png",
  x2942665: "/img/2-942665@2x.png",
  x31: "/img/3-1@2x.png",
  x4712024: "/img/4-712024@2x.png",
  x51: "/img/5-1@2x.png",
  spanText15: (
    <React.Fragment>
      About us
      <br />
    </React.Fragment>
  ),
  spanText16: (
    <React.Fragment>
      About Us
      <br />
      ayAngel Rewards
      <br />
      PayAngel RemitCare
    </React.Fragment>
  ),
  spanText17: (
    <React.Fragment>
      Help Centre
      <br />
    </React.Fragment>
  ),
  spanText18: (
    <React.Fragment>
      How It Works
      <br />
      FAQ
      <br />
      Security
      <br />
      Customer Complaints
    </React.Fragment>
  ),
  spanText19: (
    <React.Fragment>
      Legal Stuff
      <br />
    </React.Fragment>
  ),
  spanText20: (
    <React.Fragment>
      Terms And Conditions
      <br />
      Privacy Policy
      <br />
      Acceptable Use Policy
      <br />
      Cookies Policy
    </React.Fragment>
  ),
  shd1Wd91: "/img/shd1wd9-1@2x.png",
  wyrkrrLogo22: "/img/wyrkrr-logo-2@2x.png",
  subscribeToOurNewsletter: (
    <React.Fragment>
      Subscribe
      <br />
      to our newsletter
    </React.Fragment>
  ),
  enterYourEMailAddress: "Enter your e-mail address",
  spanText21: "Legal",
  spanText22: "        Privacy Policy        Impressum",
  x2_Png2Copy: "/img/2-png--2----copy@2x.png",
  text1: "",
  text2: "",
  text4: "",
  text3: "",
  rectangle88: "/img/rectangle-88@1x.png",
  rectangle97: "/img/rectangle-97@1x.png",
};
