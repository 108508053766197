import React from "react";
import styled from "styled-components";


function Group23453() {
  return (
    <Group234531>
      <Line2 src="/img/line-1@2x.svg" />
      <Line1 src="/img/line-1@2x.svg" />
    </Group234531>
  );
}

const Group234531 = styled.div`
  position: absolute;
  height: 4px;
  top: 93px;
  left: 61px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 151px;
`;

const Line2 = styled.img`
  width: 134px;
  height: 1px;
  align-self: flex-end;
  margin-bottom: -0.5px;
`;

const Line1 = styled.img`
  width: 134px;
  height: 1px;
  margin-left: 423px;
  margin-top: -0.5px;
`;

export default Group23453;
