import { css } from "styled-components";

export const JostMediumOnyx25px = css`
  color: var(--onyx);
  font-family: var(--font-family-jost);
  font-size: var(--font-size-xxl);
  font-weight: 500;
  font-style: normal;
`;

export const MontserratExtraBoldWhite16px = css`
  color: var(--white);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-m);
  font-weight: 800;
  font-style: normal;
`;

export const JostMediumBlack48px = css`
  color: var(--black);
  font-family: var(--font-family-jost);
  font-size: var(--font-size-xxxl);
  font-weight: 500;
  font-style: normal;
`;

export const JostMediumSpunPearl16px = css`
  color: var(--spun-pearl);
  font-family: var(--font-family-jost);
  font-size: var(--font-size-m);
  font-weight: 500;
  font-style: normal;
`;

export const OpensansBoldWhite20px = css`
  color: var(--white);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-xl);
  font-weight: 700;
  font-style: normal;
`;

export const JostMediumWhite48px = css`
  font-family: var(--font-family-jost);
  font-size: var(--font-size-xxxl);
  font-weight: 500;
  font-style: normal;
`;

export const OpensansNormalWhite20px = css`
  color: var(--white);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-xl);
  font-weight: 400;
  font-style: normal;
`;

export const MontserratNormalWhite16px = css`
  color: var(--white);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
`;

export const Fontawesome5brandsRegularNormalBlue = css`
  color: var(--blue);
  font-family: var(--font-family-font_awesome_5_brands-regular);
  font-size: 11.4px;
  font-weight: 400;
  font-style: normal;
`;

export const OpensansBoldWhite20px2 = css`
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-xl);
  font-weight: 700;
  font-style: normal;
`;

export const OpensansNormalWhite20px2 = css`
  color: var(--white);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-xl);
  font-weight: 400;
  font-style: normal;
`;

export const OpensansBoldBlue20px = css`
  color: var(--blue-2);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-xl);
  font-weight: 700;
  font-style: normal;
`;

export const JostMediumDarkBlue48px = css`
  color: var(--dark-blue);
  font-family: var(--font-family-jost);
  font-size: var(--font-size-xxxl);
  font-weight: 500;
  font-style: normal;
`;

export const OpensansNormalDoveGray16px = css`
  color: var(--dove-gray);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
`;

export const JostMediumDarkBlue21px = css`
  color: var(--dark-blue);
  font-family: var(--font-family-jost);
  font-size: 21px;
  font-weight: 500;
  font-style: normal;
`;

export const JostMediumMobster14px = css`
  color: var(--mobster);
  font-family: var(--font-family-jost);
  font-size: var(--font-size-s);
  font-weight: 500;
  font-style: normal;
`;

export const JumunaomjRegularNormalDoveGray20px = css`
  color: var(--dove-gray);
  font-family: var(--font-family-jumunaomj-regular);
  font-size: var(--font-size-xl);
  font-weight: 400;
  font-style: normal;
`;

export const Border1pxWhite = css`
  border: 1px solid var(--white);
`;
