import React from "react";
import styled from "styled-components";


function Group18() {
  return (
    <Group181>
      <OverlapGroup4>
        <Vector src="/img/vector-24@2x.svg" />
      </OverlapGroup4>
      <OverlapGroup3>
        <Vector1 src="/img/vector-25@2x.svg" />
      </OverlapGroup3>
      <OverlapGroup2>
        <Group5 src="/img/group-5@2x.svg" />
      </OverlapGroup2>
      <OverlapGroup5>
        <Group4 src="/img/group-4@2x.svg" />
      </OverlapGroup5>
    </Group181>
  );
}

const Group181 = styled.div`
  position: absolute;
  width: 25px;
  top: 443px;
  left: 172px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 115px;
`;

const OverlapGroup4 = styled.div`
  height: 25px;
  display: flex;
  padding: 8.1px 7.6px;
  justify-content: flex-end;
  align-items: flex-end;
  min-width: 25px;
  background-color: var(--white);
  border-radius: 12.53px;
`;

const Vector = styled.img`
  width: 9px;
  height: 7px;
`;

const OverlapGroup3 = styled.div`
  height: 25px;
  margin-top: 5px;
  display: flex;
  padding: 0 9.4px;
  align-items: center;
  min-width: 25px;
  background-color: var(--white);
  border-radius: 12.53px;
`;

const Vector1 = styled.img`
  width: 6px;
  height: 10px;
`;

const OverlapGroup2 = styled.div`
  height: 25px;
  margin-top: 5px;
  display: flex;
  padding: 7.6px 8.5px;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 25px;
  background-color: var(--white);
  border-radius: 12.53px;
`;

const Group5 = styled.img`
  width: 8px;
  height: 8px;
`;

const OverlapGroup5 = styled.div`
  height: 25px;
  margin-top: 5px;
  display: flex;
  padding: 8.1px 8.1px;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 25px;
  background-color: var(--white);
  border-radius: 12.53px;
`;

const Group4 = styled.img`
  width: 9px;
  height: 9px;
`;

export default Group18;
