import React from "react";
//import "./mcFormStyles.scss";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import CustomForm from "./CustomForm";

const MailchimpFormContainer = ({ show, onHide }) => {
  //   const postUrl = `https://wyrkrr.us13.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;
  const postUrl = `https://wyrkrr.us13.list-manage.com/subscribe/post?u=a6741177ec25c1095188dc694&id=b0db18ef4f`;
  return (
    <div className="mc__form-container">
      <MailchimpSubscribe
        url={postUrl}
        render={({ subscribe, status, message }) => (
          <div>
            <CustomForm
              showVal={show}
              onHide={onHide}
              status={status}
              message={message}
              onValidated={(formData) => subscribe(formData)}
            />
          </div>
        )}
      />
    </div>
  );
};

export default MailchimpFormContainer;
