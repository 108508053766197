import React, { useState, useEffect } from "react";
import { Container, Modal, Nav, Navbar, ThemeProvider } from "react-bootstrap";
import Group18 from "./Group18";
import styled from "styled-components";
import {
  OpensansBoldBlue20px,
  JostMediumWhite48px,
  JumunaomjRegularNormalDoveGray20px,
  Fontawesome5brandsRegularNormalBlue,
  JostMediumOnyx25px,
  OpensansNormalWhite20px,
  OpensansNormalDoveGray16px,
  OpensansBoldWhite20px2,
  MontserratExtraBoldWhite16px,
  JostMediumBlack48px,
  JostMediumMobster14px,
  OpensansNormalWhite20px2,
  JostMediumSpunPearl16px,
  JostMediumDarkBlue48px,
  Border1pxWhite,
  OpensansBoldWhite20px,
  JostMediumDarkBlue21px,
} from "../styledMixins";
import "./WYRKRRHOMEV1/WYRKRRHOMEV1.css";

const Contact = (props) => {
  const {
    getStarted1,
    contactUs,
    watchVideo,
    spanText1,
    spanText2,
    spanText3,
    spanText4,
    wyrkrrLogo21,
    howItWorks,
    number1,
    number2,
    number3,
    number4,
    createAJob,
    x29428421,
    remotelyControlDevice,
    x10866861,
    theGigWorker,
    x32348001,
    taskAndGetTheTime,
    x12483241,
    spanText5,
    spanText6,
    spanText7,
    spanText8,
    spanText9,
    spanText10,
    thisSystemHasANu,
    soIfYouReLookin,
    getStarted2,
    ellipse44,
    ellipse48,
    ellipse49,
    uiBlurTriangle11,
    uiBlurTriangle61,
    uiBlurTriangle21,
    uiBlurTriangle22,
    rectangle83,
    spanText11,
    spanText12,
    forYourSmallOrMe,
    onDemandRobotsMay,
    x27790451,
    increaseUptimeThro,
    x47120231,
    reduceLaborExpense,
    x29426641,
    spanText13,
    spanText14,
    youSetYourHours,
    workFromAnywhereYouWant,
    setYourOwnPayO,
    noNeedToCommute,
    rectangle87,
    fromGreatOurClients,
    name1,
    name2,
    ceoAtGoogleInc1,
    ceoAtGoogleInc2,
    ellipse55,
    ellipse56,
    replacingAMaintain1,
    replacingAMaintain2,
    x11,
    x2942665,
    x31,
    x4712024,
    x51,
    spanText15,
    spanText16,
    spanText17,
    spanText18,
    spanText19,
    spanText20,
    shd1Wd91,
    wyrkrrLogo22,
    subscribeToOurNewsletter,
    enterYourEMailAddress,
    spanText21,
    spanText22,
    x2_Png2Copy,
    text1,
    text2,
    text4,
    text3,
    rectangle88,
    rectangle97,
  } = props;
  return (
    <div className="container-center-horizontal">
      {/* <div className="wyrkrr-home-v1 screen"> */}
      <OverlapGroup23>
        <OverlapGroup10>
          <Vector12 src="/img/vector-12@1x.svg" />
          {/* <Vector1 src="/img/vector-1@2x.svg" /> */}
          {/* <Group1>
            <OverlapGroup1>
              <GetStarted onClick={handleShow}>{getStarted1}</GetStarted>
            </OverlapGroup1>
          </Group1> */}
          {/* <Rectangle75></Rectangle75> */}
          {/* <Rectangle79></Rectangle79> */}
          {/* <ContactUs>{contactUs}</ContactUs> */}
          {/* <WatchVideo>{watchVideo}</WatchVideo> */}
          {/* <Group2 /> */}
          <Vector2 src="/img/vector-2@2x.svg" />
          <Vector17 src="/img/vector-17@2x.svg" />
          <Vector16 src="/img/vector-16@2x.svg" />
          <Vector7 src="/img/vector-7@2x.svg" />
          <Vector5 src="/img/vector-5@2x.svg" />
          <Vector6 src="/img/vector-6@2x.svg" />
          <Vector9 src="/img/vector-9@2x.svg" />
          <Vector3 src="/img/vector-3@2x.svg" />
          <Vector10 src="/img/vector-10@2x.svg" />
          <Vector4 src="/img/vector-4@2x.svg" />
          <Vector11 src="/img/vector-11@2x.svg" />
          <HomeAboutHowItWorksFeatures>
            {/* <span className="opensans-bold-white-20px">{spanText1}</span>
      <span className="opensans-normal-white-20px-2">
        {spanText2}
      </span> */}
            <Navbar bg="light" variant="light">
              <Container>
                <Navbar.Brand href="/">
                  <img
                    src="/img/wyrkrr-logo-2@2x.png"
                    width="100px"
                    height="70px"
                  />
                </Navbar.Brand>
                <Nav className="flex-grow-1 justify-content-evenly">
                  <Nav.Item>
                    <Nav.Link href="/">Home</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="/business">Business</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="/wyrkrr">Wyrkrr</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="/contact">Contact Us</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Container>
            </Navbar>
          </HomeAboutHowItWorksFeatures>
          <MaximizeYourEffici>
            <Span01>Email: andrew@wyrkrr.com</Span01>
            <br></br>
            <br></br>
            <Span01>Phone: (515)-720-6053</Span01>
          </MaximizeYourEffici>
          {/* <Group18 /> */}
          <Vector14 src="/img/vector-14@1x.svg" />
          {/* <Frame src="/img/frame-1@1x.svg" /> */}
          <Vector18 src="/img/vector-18@2x.svg" />
          {/* <Group src="/img/group@1x.svg" /> */}
          {/* <WyrkrrLogo2 src={wyrkrrLogo21} /> */}
        </OverlapGroup10>
      </OverlapGroup23>
      {/* </div> */}
    </div>
  );
};

const OverlapGroup23 = styled.div`
  width: 1679px;
  height: 1065px;
  position: relative;
  align-self: flex-start;
  margin-top: 2px;
  margin-left: -78.5px;
`;

const OverlapGroup10 = styled.div`
  position: absolute;
  width: 1679px;
  height: 1024px;
  top: 0;
  left: 0;
`;

const Vector12 = styled.img`
  position: absolute;
  width: 1600px;
  height: 963px;
  top: 0;
  left: 79px;
`;

const Vector1 = styled.img`
  position: absolute;
  width: 200px;
  height: 19px;
  top: 424px;
  left: 420px;
`;

const Group1 = styled.div`
  position: absolute;
  height: 60px;
  top: 496px;
  left: 294px;
  display: flex;
  align-items: flex-start;
  min-width: 176px;
  cursor: pointer;
`;

const OverlapGroup1 = styled.div`
  height: 60px;
  display: flex;
  padding: 16px 4px;
  align-items: flex-start;
  min-width: 174px;
  background-color: var(--white);
  border-radius: 48.5px;
`;

const GetStarted = styled.div`
  ${OpensansBoldWhite20px2}
  width: 148px;
  min-height: 27px;
  background: linear-gradient(
    180deg,
    rgb(41.00000135600567, 123.0000002682209, 255) 0%,
    rgb(40.000028014183044, 13.999999165534973, 202.00000315904617) 100%
  );
  -webkit-background-clip: text !important;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  text-align: center;
  letter-spacing: 0;
`;

const Rectangle75 = styled.div`
  position: absolute;
  width: 174px;
  height: 60px;
  top: 35px;
  left: 1290px;
  background-color: var(--white);
  border-radius: 48.5px;
  opacity: 0.1;
`;

const Rectangle79 = styled.div`
  ${Border1pxWhite}
  position: absolute;
  width: 174px;
  height: 60px;
  top: 35px;
  left: 1290px;
  border-radius: 48.5px;
  opacity: 0.5;
`;

const ContactUs = styled.div`
  ${OpensansBoldWhite20px}
  position: absolute;
  width: 139px;
  top: 50px;
  left: 1306px;
  text-align: center;
  letter-spacing: 0;
`;

const WatchVideo = styled.div`
  ${OpensansNormalWhite20px2}
  position: absolute;
  width: 121px;
  top: 511px;
  left: 502px;
  letter-spacing: 0;
`;

const Vector2 = styled.img`
  position: absolute;
  width: 211px;
  height: 152px;
  top: 647px;
  left: 619px;
`;

const Vector17 = styled.img`
  position: absolute;
  width: 211px;
  height: 152px;
  top: 825px;
  left: 198px;
`;

const Vector16 = styled.img`
  position: absolute;
  width: 211px;
  height: 152px;
  top: 673px;
  left: 408px;
`;

const Vector7 = styled.img`
  position: absolute;
  width: 211px;
  height: 155px;
  top: 225px;
  left: 1468px;
`;

const Vector5 = styled.img`
  position: absolute;
  width: 211px;
  height: 152px;
  top: 495px;
  left: 830px;
`;

const Vector6 = styled.img`
  position: absolute;
  width: 216px;
  height: 152px;
  top: 343px;
  left: 1042px;
`;

const Vector9 = styled.img`
  position: absolute;
  width: 287px;
  height: 207px;
  top: 12px;
  left: 836px;
`;

const Vector3 = styled.img`
  position: absolute;
  width: 286px;
  height: 206px;
  top: 612px;
  left: 208px;
`;

const Vector10 = styled.img`
  position: absolute;
  width: 211px;
  height: 152px;
  top: 184px;
  left: 184px;
`;

const Vector4 = styled.img`
  position: absolute;
  width: 129px;
  height: 206px;
  top: 818px;
  left: 0;
`;

const Vector11 = styled.img`
  position: absolute;
  width: 106px;
  height: 152px;
  top: 336px;
  left: 26px;
`;

const HomeAboutHowItWorksFeatures = styled.div`
  ${OpensansBoldWhite20px}
  position: absolute;
  top: 25px;
  left: 50px;
  width: 1700px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const MaximizeYourEffici = styled.h1`
  position: absolute;
  width: 800px;
  top: 228px;
  left: 292px;
  font-family: var(--font-family-jost);
  font-weight: 500;
  color: transparent;
  font-size: var(--font-size-xxxxl);
  letter-spacing: 0;
  line-height: 70px;
`;

const Span01 = styled.span`
  color: var(--white);
`;

const Span11 = styled.span`
  color: var(--turquoise);
`;

const Vector14 = styled.img`
  position: absolute;
  width: 1px;
  height: 921px;
  top: 0;
  left: 184px;
`;

const Frame = styled.img`
  position: absolute;
  width: 305px;
  height: 530px;
  top: 223px;
  left: 962px;
`;

const Vector18 = styled.img`
  position: absolute;
  width: 211px;
  height: 152px;
  top: 380px;
  left: 1258px;
`;

const Group = styled.img`
  position: absolute;
  width: 256px;
  height: 590px;
  top: 121px;
  left: 1220px;
`;

const WyrkrrLogo2 = styled.img`
  position: absolute;
  width: 126px;
  height: 88px;
  top: 22px;
  left: 296px;
  object-fit: cover;
`;

const HowItWorks = styled.div`
  ${JostMediumBlack48px}
  position: absolute;
  width: 632px;
  top: 1007px;
  left: 560px;
  text-align: center;
  letter-spacing: 0;
  line-height: 58px;
  white-space: nowrap;
`;

const NumberContainer = styled.div`
  ${JostMediumSpunPearl16px}
  height: 25px;
  margin-top: 55px;
  margin-left: 1px;
  display: flex;
  align-items: flex-start;
  min-width: 917px;
`;

const Number = styled.div`
  min-height: 25px;
  min-width: 18px;
  text-align: center;
  letter-spacing: 0;
  line-height: 25px;
  white-space: nowrap;
`;

const Number1 = styled.div`
  min-height: 25px;
  margin-left: 280px;
  min-width: 20px;
  text-align: center;
  letter-spacing: 0;
  line-height: 25px;
  white-space: nowrap;
`;

const Number2 = styled.div`
  min-height: 25px;
  margin-left: 279px;
  min-width: 20px;
  text-align: center;
  letter-spacing: 0;
  line-height: 25px;
  white-space: nowrap;
`;

const Number3 = styled.div`
  min-height: 25px;
  margin-left: 279px;
  min-width: 21px;
  text-align: center;
  letter-spacing: 0;
  line-height: 25px;
  white-space: nowrap;
`;

const OverlapGroup7 = styled.div`
  width: 922px;
  height: 25px;
  position: relative;
  margin-top: 7px;
  margin-right: 2px;
`;

const Vector15 = styled.img`
  position: absolute;
  width: 895px;
  height: 2px;
  top: 12px;
  left: 16px;
`;

const Ellipse65 = styled.div`
  position: absolute;
  width: 25px;
  height: 25px;
  top: 0;
  left: 0;
  background-color: var(--blue);
  border-radius: 12.5px;
  opacity: 0.2;
`;

const Ellipse66 = styled.div`
  position: absolute;
  width: 25px;
  height: 25px;
  top: 0;
  left: 299px;
  background-color: var(--blue);
  border-radius: 12.5px;
  opacity: 0.2;
`;

const Ellipse68 = styled.div`
  position: absolute;
  width: 25px;
  height: 25px;
  top: 0;
  left: 598px;
  background-color: var(--blue);
  border-radius: 12.5px;
  opacity: 0.2;
`;

const Ellipse70 = styled.div`
  position: absolute;
  width: 25px;
  height: 25px;
  top: 0;
  left: 897px;
  background-color: var(--blue);
  border-radius: 12.5px;
  opacity: 0.2;
`;

const Ellipse64 = styled.div`
  position: absolute;
  width: 13px;
  height: 13px;
  top: 6px;
  left: 6px;
  background-color: var(--blue);
  border-radius: 6.5px;
`;

const Ellipse67 = styled.div`
  position: absolute;
  width: 13px;
  height: 13px;
  top: 6px;
  left: 305px;
  background-color: var(--blue);
  border-radius: 6.5px;
`;

const Ellipse69 = styled.div`
  position: absolute;
  width: 13px;
  height: 13px;
  top: 6px;
  left: 604px;
  background-color: var(--blue);
  border-radius: 6.5px;
`;

const Ellipse71 = styled.div`
  position: absolute;
  width: 13px;
  height: 13px;
  top: 6px;
  left: 903px;
  background-color: var(--blue);
  border-radius: 6.5px;
`;

const OverlapGroupContainer = styled.div`
  margin-top: 40px;
  margin-right: 3px;
  display: flex;
  align-items: flex-start;
  min-width: 1167px;
`;

const OverlapGroup13 = styled.div`
  width: 273px;
  height: 229px;
  position: relative;
`;

const Rectangle93 = styled.div`
  position: absolute;
  width: 273px;
  height: 215px;
  top: 0;
  left: 0;
  background-color: var(--white);
  box-shadow: 0px 5px 20px #d0d0d040;
`;

const Vector = styled.img`
  position: absolute;
  width: 29px;
  height: 29px;
  top: 200px;
  left: 122px;
`;

const CreateAJob = styled.div`
  ${JostMediumOnyx25px}
  position: absolute;
  width: 148px;
  top: 124px;
  left: 63px;
  text-align: center;
  letter-spacing: 0;
  line-height: 28px;
`;

const X29428421 = styled.img`
  position: absolute;
  width: 56px;
  height: 56px;
  top: 31px;
  left: 109px;
  object-fit: cover;
`;

const OverlapGroup8 = styled.div`
  width: 273px;
  height: 229px;
  position: relative;
  margin-left: 25px;
`;

const RemotelyControlDevice = styled.div`
  ${JostMediumOnyx25px}
  position: absolute;
  width: 228px;
  top: 124px;
  left: 23px;
  text-align: center;
  letter-spacing: 0;
  line-height: 28px;
`;

const X10866861 = styled.img`
  position: absolute;
  width: 56px;
  height: 56px;
  top: 31px;
  left: 112px;
  object-fit: cover;
`;

const TheGigWorker = styled.div`
  ${JostMediumOnyx25px}
  position: absolute;
  width: 250px;
  top: 124px;
  left: 12px;
  text-align: center;
  letter-spacing: 0;
  line-height: 28px;
`;

const X32348001 = styled.img`
  position: absolute;
  width: 55px;
  height: 55px;
  top: 31px;
  left: 106px;
  object-fit: cover;
`;

const TaskAndGetTheTime = styled.div`
  ${JostMediumOnyx25px}
  position: absolute;
  width: 190px;
  top: 124px;
  left: 55px;
  text-align: center;
  letter-spacing: 0;
  line-height: 28px;
`;

const X12483241 = styled.img`
  position: absolute;
  width: 52px;
  height: 52px;
  top: 33px;
  left: 111px;
  object-fit: cover;
`;

const SoWhatAreYouWai = styled.div`
  min-height: 24px;
  margin-top: 51px;
  margin-right: 1px;
  min-width: 581px;
  font-family: var(--font-family-open_sans);
  color: transparent;
  font-size: var(--font-size-xl);
  text-align: center;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
`;

const Span02 = styled.span`
  font-weight: 400;
  color: var(--black);
`;

const Span12 = styled.span`
  ${OpensansBoldBlue20px}
  text-decoration: underline;
`;

const Span13 = styled.span`
  ${JostMediumDarkBlue48px}
  text-decoration: underline;
`;

const OverlapGroup22 = styled.div`
  width: 1724px;
  height: 2762px;
  position: relative;
  align-self: flex-start;
  margin-top: 169px;
`;

const OverlapGroup9 = styled.div`
  position: absolute;
  width: 1724px;
  height: 2762px;
  top: 0;
  left: 0;
`;

const Rectangle80 = styled.div`
  position: absolute;
  width: 1600px;
  height: 1204px;
  top: 43px;
  left: 0;
  background-color: #f8f9ff;
`;

const EndlessAdvantagesWithWyrkrr = styled.div`
  ${JostMediumWhite48px}
  position: absolute;
  width: 523px;
  top: 293px;
  left: 874px;
  letter-spacing: 0;
  line-height: 58px;
`;

const ThisSystemHasANu = styled.p`
  ${OpensansNormalDoveGray16px}
  position: absolute;
  width: 511px;
  top: 441px;
  left: 874px;
  letter-spacing: 0;
  line-height: 28px;
`;

const SoIfYoureLookin = styled.p`
  ${OpensansNormalDoveGray16px}
  position: absolute;
  width: 511px;
  top: 631px;
  left: 874px;
  letter-spacing: 0;
  line-height: 28px;
`;

const OverlapGroup = styled.div`
  position: absolute;
  height: 60px;
  top: 730px;
  left: 874px;
  display: flex;
  padding: 16px 17px;
  align-items: flex-start;
  min-width: 174px;
  background-color: var(--white);
  border-radius: 48.5px;
`;

const GetStarted1 = styled.div`
  ${OpensansBoldWhite20px2}
  width: 139px;
  min-height: 27px;
  background: linear-gradient(
    180deg,
    rgb(41.00000135600567, 123.0000002682209, 255) 0%,
    rgb(40.000028014183044, 13.999999165534973, 202.00000315904617) 100%
  );
  -webkit-background-clip: text !important;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  text-align: center;
  letter-spacing: 0;
`;

const Ellipse44 = styled.img`
  position: absolute;
  width: 738px;
  height: 738px;
  top: 205px;
  left: 62px;
  object-fit: cover;
`;

const Ellipse50 = styled.div`
  ${Border1pxWhite}
  position: absolute;
  width: 738px;
  height: 738px;
  top: 205px;
  left: 62px;
  border-radius: 369px;
`;

const Ellipse48 = styled.img`
  position: absolute;
  width: 670px;
  height: 670px;
  top: 239px;
  left: 96px;
  object-fit: cover;
`;

const Ellipse51 = styled.div`
  ${Border1pxWhite}
  position: absolute;
  width: 670px;
  height: 670px;
  top: 239px;
  left: 96px;
  border-radius: 335px;
`;

const Ellipse49 = styled.img`
  position: absolute;
  width: 606px;
  height: 606px;
  top: 275px;
  left: 128px;
  object-fit: cover;
`;

const UiBlurTriangle11 = styled.img`
  position: absolute;
  width: 200px;
  height: 200px;
  top: 153px;
  left: 358px;
  object-fit: cover;
`;

const UiBlurTriangle61 = styled.img`
  position: absolute;
  width: 200px;
  height: 200px;
  top: 680px;
  left: 96px;
  object-fit: cover;
`;

const UiBlurTriangle21 = styled.img`
  position: absolute;
  width: 200px;
  height: 200px;
  top: 690px;
  left: 576px;
  object-fit: cover;
`;

const UiBlurTriangle22 = styled.img`
  position: absolute;
  width: 98px;
  height: 98px;
  top: 0;
  left: 246px;
  object-fit: cover;
`;

const Rectangle81 = styled.div`
  position: absolute;
  width: 366px;
  height: 56px;
  top: 12px;
  left: 1144px;
  background-color: var(--blue);
  opacity: 0.1;
`;

const Rectangle82 = styled.div`
  position: absolute;
  width: 366px;
  height: 26px;
  top: 42px;
  left: 1144px;
  background-color: var(--blue);
  opacity: 0.2;
`;

const Rectangle83 = styled.img`
  position: absolute;
  width: 1600px;
  height: 1103px;
  top: 1024px;
  left: 0;
`;

const TheKeyBenefitsOfUsingWyrkrr = styled.div`
  ${JostMediumWhite48px}
  position: absolute;
  width: 402px;
  top: 1771px;
  left: 215px;
  letter-spacing: 0;
  line-height: 55.5px;
`;

const Span04 = styled.span`
  font-family: var(--font-family-jost);
  font-weight: 500;
  color: var(--white);
  font-size: var(--font-size-xxxl);
`;

const Span14 = styled.span`
  font-family: var(--font-family-jost);
  font-weight: 500;
  color: var(--turquoise);
  font-size: var(--font-size-xxxl);
  text-decoration: underline;
`;

const ForYourSmallOrMe = styled.div`
  position: absolute;
  top: 1290px;
  left: 714px;
  font-family: var(--font-family-open_sans);
  font-weight: 700;
  color: var(--white);
  font-size: var(--font-size-xxl);
  letter-spacing: 0;
  line-height: 35px;
`;

const Rectangle76 = styled.div`
  position: absolute;
  width: 506px;
  height: 168px;
  top: 1377px;
  left: 714px;
  background-color: #ff7a58;
`;

const Rectangle78 = styled.div`
  position: absolute;
  width: 506px;
  height: 168px;
  top: 1767px;
  left: 714px;
  background-color: #00b886;
`;

const Rectangle77 = styled.div`
  position: absolute;
  width: 506px;
  height: 168px;
  top: 1572px;
  left: 763px;
  background-color: var(--blue);
`;

const Ellipse52 = styled.div`
  position: absolute;
  width: 78px;
  height: 78px;
  top: 1422px;
  left: 743px;
  background-color: var(--white);
  border-radius: 39px;
`;

const Ellipse54 = styled.div`
  position: absolute;
  width: 78px;
  height: 78px;
  top: 1812px;
  left: 743px;
  background-color: var(--white);
  border-radius: 39px;
`;

const Ellipse53 = styled.div`
  position: absolute;
  width: 78px;
  height: 78px;
  top: 1617px;
  left: 792px;
  background-color: var(--white);
  border-radius: 39px;
`;

const OnDemandRobotsMay = styled.div`
  ${OpensansNormalWhite20px}
  position: absolute;
  width: 339px;
  top: 1403px;
  left: 846px;
  letter-spacing: 0;
  line-height: 28px;
`;

const X27790451 = styled.img`
  position: absolute;
  width: 42px;
  height: 42px;
  top: 1437px;
  left: 761px;
  object-fit: cover;
`;

const IncreaseUptimethro = styled.div`
  ${OpensansNormalWhite20px}
  position: absolute;
  width: 310px;
  top: 1809px;
  left: 846px;
  letter-spacing: 0;
  line-height: 28px;
`;

const X47120231 = styled.img`
  position: absolute;
  width: 41px;
  height: 41px;
  top: 1829px;
  left: 763px;
  object-fit: cover;
`;

const ReduceLaborExpense = styled.div`
  ${OpensansNormalWhite20px}
  position: absolute;
  width: 262px;
  top: 1628px;
  left: 896px;
  letter-spacing: 0;
  line-height: 28px;
`;

const X29426641 = styled.img`
  position: absolute;
  width: 37px;
  height: 37px;
  top: 1637px;
  left: 812px;
  object-fit: cover;
`;

const Frame1 = styled.img`
  position: absolute;
  width: 282px;
  height: 367px;
  top: 1377px;
  left: 286px;
`;

const Frame2 = styled.img`
  position: absolute;
  width: 428px;
  height: 492px;
  top: 790px;
  left: 1179px;
`;

const Rectangle84 = styled.div`
  position: absolute;
  width: 366px;
  height: 56px;
  top: 1033px;
  left: 165px;
  background-color: var(--blue);
  transform: rotate(-6.35deg);
  opacity: 0.1;
`;

const Rectangle85 = styled.div`
  position: absolute;
  width: 366px;
  height: 26px;
  top: 1063px;
  left: 163px;
  background-color: var(--blue);
  transform: rotate(-6.35deg);
  opacity: 0.5;
`;

const Group23456 = styled.img`
  position: absolute;
  width: 608px;
  height: 634px;
  top: 2128px;
  left: 1116px;
`;

const Frame3 = styled.img`
  position: absolute;
  width: 74px;
  height: 96px;
  top: 2416px;
  left: 1131px;
`;

const Frame4 = styled.img`
  position: absolute;
  width: 43px;
  height: 56px;
  top: 2352px;
  left: 1309px;
`;

const Frame5 = styled.img`
  position: absolute;
  width: 114px;
  height: 82px;
  top: 2116px;
  left: 1294px;
`;

const Frame6 = styled.img`
  position: absolute;
  width: 75px;
  height: 130px;
  top: 2248px;
  left: 1030px;
`;

const Frame7 = styled.img`
  position: absolute;
  width: 92px;
  height: 102px;
  top: 2476px;
  left: 1317px;
`;

const Frame8 = styled.img`
  position: absolute;
  width: 60px;
  height: 84px;
  top: 2499px;
  left: 1492px;
`;

const Frame9 = styled.img`
  position: absolute;
  width: 60px;
  height: 84px;
  top: 2222px;
  left: 1301px;
`;

const Group23457 = styled.img`
  position: absolute;
  width: 499px;
  height: 446px;
  top: 2182px;
  left: 1085px;
`;

const ForGigWorkersWithWyrkrr = styled.div`
  ${JostMediumWhite48px}
  position: absolute;
  width: 402px;
  top: 2253px;
  left: 215px;
  letter-spacing: 0;
  line-height: 55.5px;
`;

const YouSetYourHours = styled.div`
  ${JostMediumOnyx25px}
  position: absolute;
  width: 536px;
  top: 2403px;
  left: 257px;
  letter-spacing: 0;
  line-height: 35px;
  white-space: nowrap;
`;

const WorkFromAnywhereYouWant = styled.div`
  ${JostMediumOnyx25px}
  position: absolute;
  width: 408px;
  top: 2472px;
  left: 257px;
  letter-spacing: 0;
  line-height: 35px;
  white-space: nowrap;
`;

const SetYourOwnPayO = styled.div`
  ${JostMediumOnyx25px}
  position: absolute;
  width: 674px;
  top: 2537px;
  left: 257px;
  letter-spacing: 0;
  line-height: 35px;
  white-space: nowrap;
`;

const NoNeedToCommute = styled.div`
  ${JostMediumOnyx25px}
  position: absolute;
  width: 583px;
  top: 2602px;
  left: 257px;
  letter-spacing: 0;
  line-height: 35px;
  white-space: nowrap;
`;

const Vector8 = styled.img`
  position: absolute;
  width: 25px;
  height: 25px;
  top: 2406px;
  left: 215px;
`;

const Vector13 = styled.img`
  position: absolute;
  width: 25px;
  height: 26px;
  top: 2478px;
  left: 215px;
`;

const Vector19 = styled.img`
  position: absolute;
  width: 25px;
  height: 26px;
  top: 2542px;
  left: 215px;
`;

const Vector20 = styled.img`
  position: absolute;
  width: 25px;
  height: 25px;
  top: 2610px;
  left: 215px;
`;

const FlexRow = styled.div`
  height: 1910px;
  align-self: flex-start;
  margin-top: 74px;
  display: flex;
  align-items: flex-start;
  min-width: 3200px;
`;

const FlexCol = styled.div`
  width: 1600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 1613px;
`;

const OverlapGroup21 = styled.div`
  width: 1600px;
  height: 1352px;
  position: relative;
`;

const OverlapGroup11 = styled.div`
  position: absolute;
  width: 1600px;
  height: 1352px;
  top: 0;
  left: 0;
`;

const Rectangle87 = styled.img`
  position: absolute;
  width: 1600px;
  height: 1065px;
  top: 0;
  left: 0;
  object-fit: cover;
`;

const FromGreatOurClients = styled.div`
  ${JostMediumBlack48px}
  position: absolute;
  width: 618px;
  top: 122px;
  left: 492px;
  text-align: center;
  letter-spacing: 0;
  line-height: 55.5px;
  white-space: nowrap;
`;

const Rectangle89 = styled.div`
  position: absolute;
  width: 366px;
  height: 56px;
  top: 816px;
  left: 1069px;
  background-color: var(--blue);
  transform: rotate(-173.65deg);
  opacity: 0.5;
`;

const Rectangle90 = styled.div`
  position: absolute;
  width: 366px;
  height: 26px;
  top: 846px;
  left: 1071px;
  background-color: #0042b0;
  transform: rotate(-173.65deg);
  opacity: 0.7;
`;

const Rectangle91 = styled.div`
  position: absolute;
  width: 574px;
  height: 273px;
  top: 277px;
  left: 215px;
  background-color: var(--white);
`;

const Rectangle92 = styled.div`
  position: absolute;
  width: 574px;
  height: 273px;
  top: 277px;
  left: 811px;
  background-color: var(--white);
`;

const Image = styled.img`
  position: absolute;
  width: 33px;
  height: 24px;
  top: 321px;
  left: 711px;
`;

const Image1 = styled.img`
  position: absolute;
  width: 33px;
  height: 24px;
  top: 321px;
  left: 1307px;
`;

const Name = styled.div`
  ${JostMediumDarkBlue21px}
  position: absolute;
  top: 341px;
  left: 265px;
  letter-spacing: 0;
  line-height: 55.5px;
  white-space: nowrap;
`;

const Name1 = styled.div`
  ${JostMediumDarkBlue21px}
  position: absolute;
  top: 341px;
  left: 861px;
  letter-spacing: 0;
  line-height: 55.5px;
  white-space: nowrap;
`;

const CEOAtGoogleINC = styled.p`
  ${JostMediumMobster14px}
  position: absolute;
  top: 343px;
  left: 376px;
  letter-spacing: 0;
  line-height: 55.5px;
  white-space: nowrap;
`;

const CEOAtGoogleINC1 = styled.p`
  ${JostMediumMobster14px}
  position: absolute;
  top: 343px;
  left: 972px;
  letter-spacing: 0;
  line-height: 55.5px;
  white-space: nowrap;
`;

const Ellipse55 = styled.img`
  position: absolute;
  width: 92px;
  height: 92px;
  top: 223px;
  left: 256px;
  object-fit: cover;
`;

const Ellipse56 = styled.img`
  position: absolute;
  width: 92px;
  height: 92px;
  top: 223px;
  left: 852px;
  object-fit: cover;
`;

const ReplacingAMaintain = styled.div`
  ${JumunaomjRegularNormalDoveGray20px}
  position: absolute;
  width: 478px;
  top: 401px;
  left: 266px;
  letter-spacing: 0;
  line-height: 34px;
`;

const ReplacingAMaintain1 = styled.div`
  ${JumunaomjRegularNormalDoveGray20px}
  position: absolute;
  width: 478px;
  top: 401px;
  left: 862px;
  letter-spacing: 0;
  line-height: 34px;
`;

const Ellipse57 = styled.div`
  position: absolute;
  width: 14px;
  height: 14px;
  top: 624px;
  left: 745px;
  background-color: var(--quick-silver);
  border-radius: 7px;
`;

const Ellipse58 = styled.div`
  position: absolute;
  width: 14px;
  height: 14px;
  top: 624px;
  left: 774px;
  background-color: #31a6ff;
  border-radius: 7px;
`;

const Ellipse59 = styled.div`
  position: absolute;
  width: 14px;
  height: 14px;
  top: 624px;
  left: 803px;
  background-color: var(--quick-silver);
  border-radius: 7px;
`;

const Ellipse60 = styled.div`
  position: absolute;
  width: 14px;
  height: 14px;
  top: 624px;
  left: 832px;
  background-color: var(--quick-silver);
  border-radius: 7px;
`;

const X11 = styled.img`
  position: absolute;
  width: 116px;
  height: 24px;
  top: 726px;
  left: 317px;
  object-fit: cover;
`;

const X2942665 = styled.img`
  position: absolute;
  width: 111px;
  height: 35px;
  top: 726px;
  left: 539px;
  object-fit: cover;
`;

const X31 = styled.img`
  position: absolute;
  width: 96px;
  height: 26px;
  top: 735px;
  left: 756px;
  object-fit: cover;
`;

const X4712024 = styled.img`
  position: absolute;
  width: 121px;
  height: 31px;
  top: 735px;
  left: 958px;
  object-fit: cover;
`;

const X51 = styled.img`
  position: absolute;
  width: 98px;
  height: 32px;
  top: 734px;
  left: 1185px;
  object-fit: cover;
`;

const AboutUsAboutUsAy = styled.p`
  ${MontserratExtraBoldWhite16px}
  position: absolute;
  width: 254px;
  top: 1053px;
  left: 475px;
  letter-spacing: 0;
  line-height: 55.1px;
`;

const HelpCentreHowItW = styled.p`
  ${MontserratExtraBoldWhite16px}
  position: absolute;
  width: 262px;
  top: 1053px;
  left: 822px;
  letter-spacing: 0;
  line-height: 55.1px;
`;

const LegalStuffTermsAn = styled.p`
  ${MontserratExtraBoldWhite16px}
  position: absolute;
  width: 218px;
  top: 1053px;
  left: 1169px;
  letter-spacing: 0;
  line-height: 55.1px;
`;

const SHd1Wd91 = styled.img`
  position: absolute;
  width: 2px;
  height: 1px;
  top: 1268px;
  left: 1471px;
  object-fit: cover;
`;

const WyrkrrLogo21 = styled.img`
  position: absolute;
  width: 126px;
  height: 88px;
  top: 1065px;
  left: 215px;
  object-fit: cover;
`;

const FlexRow1 = styled.div`
  height: 72px;
  margin-top: 55px;
  margin-right: 6px;
  display: flex;
  align-items: flex-end;
  min-width: 1164px;
`;

const SubscribeToOurNewsletter = styled.div`
  min-height: 72px;
  min-width: 289px;
  font-family: var(--font-family-montserrat);
  font-weight: 700;
  color: var(--white);
  font-size: 32px;
  letter-spacing: 0;
  line-height: 35.5px;
`;

const OverlapGroup20 = styled.div`
  margin-left: 145px;
  display: flex;
  align-items: center;
  min-width: 730px;
`;

const EnterYourEMailAddress = styled.div`
  min-height: 23px;
  margin-bottom: 3px;
  min-width: 248px;
  font-family: var(--font-family-font_awesome_5_free-regular);
  font-weight: 400;
  color: var(--white);
  font-size: var(--font-size-xl);
  letter-spacing: 0;
`;

const OverlapGroup6 = styled.div`
  width: 688px;
  height: 70px;
  position: relative;
  margin-left: 400px;
`;

const Group23450 = styled.div`
  position: absolute;
  height: 6px;
  top: 32px;
  left: 0;
  display: flex;
  align-items: center;
  min-width: 40px;
`;

const Rectangle30 = styled.div`
  width: 36px;
  height: 2px;
  background-color: var(--white);
`;

const Vector141 = styled.img`
  width: 4px;
  height: 6px;
  align-self: flex-end;
  margin-bottom: -5969px;
`;

const Ellipse441 = styled.div`
  ${Border1pxWhite}
  position: absolute;
  width: 70px;
  height: 70px;
  top: 0;
  left: 12px;
  border-radius: 35px;
`;

const Vector131 = styled.img`
  position: absolute;
  width: 687px;
  height: 1px;
  top: 65px;
  left: 1px;
`;

const GroupContainer = styled.div`
  margin-top: 98px;
  margin-right: 4px;
  display: flex;
  align-items: center;
  min-width: 1164px;
`;

const OverlapGroup15 = styled.div`
  width: 476px;
  height: 30px;
  position: relative;
  margin-bottom: 2px;
`;

const LegalPrivacyPolicyImpressum = styled.div`
  position: absolute;
  width: 476px;
  top: 0;
  left: 0;
  font-family: var(--font-family-montserrat);
  font-weight: 700;
  color: #474747;
  font-size: var(--font-size-l);
  letter-spacing: 0;
`;

const Span09 = styled.span`
  font-weight: 700;
  color: var(--white);
`;

const Span19 = styled.span`
  font-weight: 400;
  color: var(--white);
`;

const X2PNG2Copy = styled.img`
  position: absolute;
  width: 150px;
  height: 28px;
  top: 2px;
  left: 0;
`;

const Group23449 = styled.div`
  margin-left: 525px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 38px;
`;

const Text1 = styled.div`
  width: 8px;
  min-height: 13px;
  margin-bottom: 1.26px;
  font-family: var(--font-family-font_awesome_5_brands-regular);
  font-weight: 400;
  color: var(--white);
  font-size: 11.4px;
  letter-spacing: 0;
`;

const Ellipse8 = styled.img`
  width: 36px;
  height: 36px;
  align-self: flex-end;
  margin-left: 1196px;
  margin-bottom: -6105px;
`;

const OverlapGroup17 = styled.div`
  height: 36px;
  margin-left: 4px;
  display: flex;
  padding: 10.4px 12px;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 36px;
  background-image: url(/img/ellipse-61@2x.svg);
  background-size: 100% 100%;
`;

const Text2 = styled.div`
  ${Fontawesome5brandsRegularNormalBlue}
  width: 11px;
  min-height: 13px;
  letter-spacing: 0;
`;

const OverlapGroup19 = styled.div`
  height: 36px;
  margin-left: 6px;
  display: flex;
  padding: 10.4px 11.3px;
  align-items: flex-start;
  min-width: 36px;
  background-image: url(/img/ellipse-63@2x.svg);
  background-size: 100% 100%;
`;

const Text4 = styled.div`
  ${Fontawesome5brandsRegularNormalBlue}
  width: 13px;
  min-height: 13px;
  letter-spacing: 0;
`;

const OverlapGroup16 = styled.div`
  height: 36px;
  margin-left: 6px;
  display: flex;
  padding: 10.4px 12px;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 36px;
  background-image: url(/img/ellipse-62@2x.svg);
  background-size: 100% 100%;
`;

const OverlapGroup18 = styled.div`
  width: 1600px;
  height: 1103px;
  position: relative;
  align-self: flex-end;
`;

const Rectangle88 = styled.img`
  position: absolute;
  width: 1600px;
  height: 913px;
  top: 95px;
  left: 0;
`;

const Rectangle97 = styled.img`
  position: absolute;
  width: 1600px;
  height: 1103px;
  top: 0;
  left: 0;
`;

export default Contact;
