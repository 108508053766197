import React from "react";
import styled from "styled-components";


function Group2() {
  return (
    <Group21>
      <Vector src="/img/vector-23@2x.svg" />
    </Group21>
  );
}

const Group21 = styled.div`
  position: absolute;
  height: 56px;
  top: 499px;
  left: 628px;
  display: flex;
  padding: 0 21px;
  align-items: center;
  min-width: 56px;
  background-color: var(--white);
  border-radius: 28px;
`;

const Vector = styled.img`
  width: 14px;
  height: 16px;
`;

export default Group2;
