import React, { useState, useEffect } from "react";
//import "./mcFormStyles.scss";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { Form, Button, Modal } from "react-bootstrap";

const CustomForm = ({ showVal, onHide, status, message, onValidated }) => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [interest, setInterest] = useState("");
  const [show, setShow] = useState(showVal);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  useEffect(() => {
    if (status === "success") clearFields();
  }, [status]);

  useEffect(() => {
    setShow(showVal);
  }, [showVal]);

  const clearFields = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setInterest([]);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name);
    console.log(value);
    if (name == "firstName") {
      setFirstName(value);
    } else if (name == "lastName") {
      setLastName(value);
    } else if (name == "email") {
      setEmail(value);
    } else if (name == "interest") {
      setInterest(value);
    }
  };

  // const handleInterestChange = (e) => {
  //   const { name, value } = e.target;
  //   console.log(name);
  //   console.log(value);
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    email &&
      firstName &&
      lastName &&
      email.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email,
        MERGE1: firstName,
        MERGE2: lastName,
        MERGE3: interest,
      });
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>
          {status === "success"
            ? "Success!"
            : "Join our email list for future updates."}
        </Modal.Title>
        {status === "sending" && (
          <div className="mc__alert mc__alert--sending">sending...</div>
        )}
        {status === "error" && (
          <div
            className="mc__alert mc__alert--error"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === "success" && (
          <div
            className="mc__alert mc__alert--success"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={(e) => handleSubmit(e)}>
          {status !== "success" ? (
            <div>
              <Form.Group className="mb-3">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  name="firstName"
                  onChange={handleChange}
                  type="text"
                  value={firstName}
                  placeholder="First Name"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  name="lastName"
                  onChange={handleChange}
                  type="text"
                  value={lastName}
                  placeholder="Last Name"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  name="email"
                  onChange={handleChange}
                  type="email"
                  value={email}
                  placeholder="your@email.com"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Interest</Form.Label>
                <Form.Control
                  name="interest"
                  onChange={handleChange}
                  type="text"
                  value={interest}
                  placeholder="Example: Business, Worker, Investor"
                />
              </Form.Group>
              {/* <Form.Group>
                <Form.Label>Interest</Form.Label>
                <Form.Check
                  name="Business"
                  label="Business"
                  onChange={handleInterestChange}
                  type="checkbox"
                  value={interest}
                />
                <Form.Check
                  name="Wyrkrr"
                  label="Wyrkrr"
                  onChange={handleInterestChange}
                  type="checkbox"
                  value={interest}
                />
                <Form.Check
                  name="Investor"
                  label="Investor"
                  onChange={handleInterestChange}
                  type="checkbox"
                  value={interest}
                />
              </Form.Group> */}
            </div>
          ) : null}
          <br />
          {status === "success" ? (
            <Button onClick={onHide}>Close</Button>
          ) : (
            <Button
              type="submit"
              //   formValues={[email, firstName, lastName, interest]}
            >
              Submit
            </Button>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CustomForm;
